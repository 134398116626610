import * as React from 'react';
import { useContractWrite, useAccount, useContractRead } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import contractData from 'Metamorph.json';
import { ethers } from 'ethers';
import { Loading } from 'components/loading';

import visual from 'assets/omida.png';
import tree from 'assets/tree.png';
import flower from 'assets/flower.png';
import sun from 'assets/sun.png';
import moon from 'assets/moon.png';



const ConnectToMint = () => {
  return (
    <ConnectButton.Custom>
      {({
        openConnectModal,
      }) => (<a className="cursor-pointer" onClick={() => openConnectModal()}>connect a wallet to mint</a>)}
    </ConnectButton.Custom>
  );
};

const GetAWallet = () => {
  return (
    <ConnectButton.Custom>
      {({
        openConnectModal,
      }) => (<a className="cursor-pointer" onClick={() => openConnectModal()}>get a wallet</a>)}
    </ConnectButton.Custom>
  );
};

type Props = {
  toggleMode: () => void;
  mode: 'light' | 'dark'
}

export const Home = ({ toggleMode, mode }: Props) => {
  const total = 3330;
  const price = ethers.utils.parseEther('0.03');
  
  const [userMintCount, setUserMintCount] = React.useState(2);
  const inc = React.useCallback(() => {
    if (userMintCount < 10) {
      setUserMintCount(userMintCount + 1);
    }
  }, [userMintCount]);
  const dec = React.useCallback(() => {
    if (userMintCount > 1) {
      setUserMintCount(userMintCount - 1);
    }
  }, [userMintCount]);

  const { data: account, isLoading: isLoadingAccount } = useAccount();

  const { write: mint,  } = useContractWrite(
    {
      addressOrName: contractData.address,
      contractInterface: contractData.abi,
    },
    'mint',
    { 
      args: [userMintCount],
      overrides: { value: price.mul(userMintCount) },
      onError(error) {
        alert('haiyaaa, something went wrong, try again');
      },      
    }
  );

  const { data: totalMinted, isLoading: isLoadingTotalMinted } = useContractRead(
    {
      addressOrName: contractData.address,
      contractInterface: contractData.abi,
    },
    'totalMinted',
    {
      watch: true
    }
  );


  const { data: userBalance, isLoading: isLoadingBalance } = useContractRead(
    {
      addressOrName: contractData.address,
      contractInterface: contractData.abi,
    },
    'balanceOf',
    {
      watch: true,
      args: [account?.address],
    }
  );


  const isLoading = isLoadingAccount || isLoadingTotalMinted;
  const left = total - totalMinted?.toNumber();

  const marketLink = userBalance?.toNumber() ? 'https://opensea.io/account/metamorph-ink?tab=collected' : 'https://opensea.io/collection/metamorph-ink';
  const userBalanceNumber = userBalance?.toNumber() || 0;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <a className="mode-toggle" onClick={toggleMode}>
        <img src={mode === 'light' ? sun : moon} />
      </a>
      <div className="container mx-auto">
        <header className="flex flex-col py-4 mb-8">
          <div>
            <h1>Metamorph</h1>
            <h2>a sketchy nft collection</h2>
            <p>{total} caterpillars living on ethereum</p>
            <p>⭐ starring ⭐</p>
            <img src={visual} style={{ width: 500, maxWidth: '80%' }}  className="m-10 mx-auto" />
            <p>0.03 eth + gas, 10 / wallet limit</p>
            <p className="mb-4">{left} left</p>
            {!account?.address ? (<div  className="mb-4"><ConnectToMint /></div>) : (
              <p className="select-none mb-4">
                <span className="p-4 cursor-pointer" onClick={dec}>-</span>
                <a className="underline cursor-pointer" onClick={() => mint()}>mint {userMintCount}</a>
                <span className="p-4 cursor-pointer" onClick={inc}>+</span>
              </p>
            )}
          
            {userBalanceNumber ? <p className="mb-4">you own {userBalanceNumber} {userBalanceNumber === 1 ? 'catterpillar' : 'catterpillars'}!<br/>nice</p> : null}
            <p><a className="underline" href={marketLink} target="_blank" rel="noreferrer">check them out on Opensea</a></p>
          </div>
        </header>

        <main className="mb-8 px-4">
        
          <h2>wait, but why</h2>
          <p>just to showcase my web3 dev skills</p>
          <p>the &quot;art&quot; is hand drawn and is just an excuse to build something</p>

          <h2 className="mt-4">roadmap</h2>
          <h3>0%</h3>
          <p><a href="https://etherscan.io/address/0x7FB1d116e9243fc2c06572B2704e40E85a2F230D#code#F15#L46" target="_blank" rel="noreferrer" className="underline">25% of all sales are donated on-chain to Ukraine as eth</a></p>
          <p>i can&quot;t hype them up by buying myself 😢</p> 
          |
          <h3>50%</h3>
          <p className="line-through">add wallet connect / mobile support</p>
          <p>document &amp; open source the code</p>
          |
          <h3>100%</h3>
          <p>quit my job, focus entirely on this space</p>
          <p>holders will get access to future projects i&apos;m involved in</p>
          <p>other stuff</p>

          <h2 className="mt-4">faq</h2>
          <p>how do i mint? <GetAWallet />, get some ethereum, push the &quot;mint&quot; button above</p>
          <p>how many nfts are there? {total}, 30 reserved for collaborations</p>
          <p>reveal date? revealed</p>

          <h2 className="mt-4">get in touch</h2>
          <p><a href="http://twitter.com/@arosca" target="_blank" rel="noreferrer">@arosca</a></p>

          <img src={tree} className="tree" />
          <img src={flower} className="flower" />
        </main>
      </div>
    </>
  );
};