import * as React from 'react';

import '@rainbow-me/rainbowkit/styles.css';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { chain, createClient, configureChains, WagmiConfig, defaultChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import Cookies from 'js-cookie';
// import { InjectedConnector } from 'wagmi/connectors/injected';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { getDefaultWallets, wallet, connectorsForWallets, RainbowKitProvider, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
import { Home } from 'screens/home/';

import './app.css';

const firebaseConfig = {
  apiKey: 'AIzaSyBtd7Po8uPnbDpgT4OQisCfhk5Hjeiq2BM',
  authDomain: 'metamorph-6a30f.firebaseapp.com',
  projectId: 'metamorph-6a30f',
  storageBucket: 'metamorph-6a30f.appspot.com',
  messagingSenderId: '241237941369',
  appId: '1:241237941369:web:32952c3cb747edc3cb88a8',
  measurementId: 'G-XSMZD1RSVR'
};

// Initialize Firebase
const fapp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fapp);

// const localhostProvider = jsonRpcProvider({
//   rpc: (chain) => ({
//     http: 'http://127.0.0.1:8545/',
//     webSocket: 'wss://127.0.0.1:8545/'
//   }),
// });

// const { chains, provider } = configureChains(
//   [chain.mainnet],
//   [infuraProvider({ infuraId: '3a177d8f76f247cc83a72293adc9baac' }), publicProvider()],
// );

const { chains, provider, webSocketProvider } = configureChains([chain.mainnet], [
  infuraProvider({ infuraId: '3a177d8f76f247cc83a72293adc9baac' }),
  publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: 'Metamorph.ink',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider
});

const COOKIE_KEY = 'metamorph.ink.theme.mode';
const prevMode = Cookies.get(COOKIE_KEY) || 'light';

type ModeType = 'light' | 'dark';

function App() {
  const [mode, setMode] = React.useState<ModeType>(prevMode as ModeType);
  React.useEffect(() => {
    document.body.classList.add(mode);
  }, []);

  const toggleMode = React.useCallback(() => {
    if (mode === 'light') {
      document.body.classList.replace('light', 'dark');
      setMode('dark');
      Cookies.set(COOKIE_KEY, 'dark');
      return;
    }
    document.body.classList.replace('dark', 'light');
    setMode('light');
    Cookies.set(COOKIE_KEY, 'light');
  }, [mode]);

  const light = lightTheme();
  const dark = darkTheme();
  const theme = mode === 'light' ? light : dark;

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={theme}>
        <Home toggleMode={toggleMode} mode={mode} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
